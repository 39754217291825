<template>
  <app-sidebar-form
    id="crewForm"
    :edition="value"
    :edition-mode-title="crewMember.id ? 'edit' : 'add'"
    :entity="crewMember._attributes"
    :title="$t('crew.title')"
    form-name="crew-form"
    no-close-on-submit
    @update:edition="$emit('input', $event)"
    @update:entity="onSubmit($event)"
  >
    <template #fields="data">
      <b-overlay :show="!data.item">
        <!--Passenger FullName-->
        <app-input id="fullName" v-model="data.item.fullName" :label="$t('user.full_name')" required />

        <!-- gender -->
        <enum-select id="sexType" v-model="data.item.sex" :label="$t('registration.registration_user.gender')" enum-class="SexType" required />

        <!--Aircraft-->
        <validation-provider>
          <b-form-group :label="$t('crew.aircrafts')" label-for="aircraft">
            <v-select
              v-if="organizationAircrafts"
              v-model="data.item.organizationAircraftIds"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="organizationAircrafts"
              :placeholder="$t('crew.aircrafts')"
              :reduce="aircraft => aircraft.id"
              clearable
              input-id="aircraft"
              label="name"
              multiple
            />
          </b-form-group>
        </validation-provider>

        <!-- Nationality -->
        <enum-select
          v-model="data.item.nationalityCountry.code"
          :clearable="false"
          :label="$t('common.country')"
          enum-class="CountryCode"
          input-id="countriyCode"
          required
          text="text"
        />
      </b-overlay>
    </template>
  </app-sidebar-form>
</template>

<script>
import { mapState } from 'vuex'
import { VBToggle } from 'bootstrap-vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { fetchOrganizationAircraftsRequest } from '@/request/globalApi/requests/organizationAircraftRequests'
import AppSidebarForm from '@/components/AppSidebarForm.vue'

export default {
  name: 'SidebarCrewMemberForm',

  components: {
    AppSidebarForm,
  },

  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    crewMember: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      organizationAircrafts: null,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  mounted() {
    this.APIFetchOrganizationAircrafts()
  },
  methods: {
    APIFetchOrganizationAircrafts() {
      if (this.$can('ORGANIZATION_ACCOUNT_AIRCRAFT_VIEW')) {
        fetchOrganizationAircraftsRequest(this.selectedOrganization.id, { getAllItems: true }).then(({ data }) => {
          this.organizationAircrafts = data.organizationAircrafts.map(aircraft => {
            const identificator = aircraft.callSign || aircraft.registration
            const name = aircraft.aircraft?.name
            return {
              ...aircraft,
              nameInfo: `${name ? `${name} - ` : ''}${identificator}`,
            }
          })
        })
      }
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.addCrewSubmitRules) {
          this.$refs.addCrewSubmitRules.reset()
        }
      })
    },
    // PATCH/POST
    onSubmit(payload) {
      this.crewMember.set(payload)
      const action = this.crewMember.id ? 'patch' : 'post'
      this.crewMember[action]().then(response => {
        this.resetValidator()
        if (action === 'post') {
          this.crewMember.set(response.data)
          this.$emit('add-crew-member', this.crewMember)
        }
        this.$emit('input', false)
      })
    },
  },
}
</script>
